/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // random(min,max)
  function randomIntFromInterval(min,max) {
      return Math.floor(Math.random()*(max-min+1)+min);
  }

  // Animate the homepage calls to action.
  function amimate_hp_ctas() {

    // dim some vars
    var questions = $('.cta_q');
    var current_question = $('.cta_q.active');
    var curr_q_index = questions.index(current_question);
    var next_q_index = (curr_q_index + 1) % questions.length;

    var answers = $('.cta_a');
    var current_answer = $('.cta_a.active');
    var curr_a_index = answers.index(current_answer);
    var next_a_index = (curr_a_index + 1) % answers.length;

    // Fade out current question.
    questions.eq(curr_q_index).fadeOut('fast', function() {
      // Then fade out the current answer.
      answers.eq(curr_a_index).fadeOut('fast', function() {
        // Remove the active class on the current q/a:
        questions.eq(curr_q_index).removeClass('active');
        answers.eq(curr_a_index).removeClass('active');
        // Then fade in the next question.
        questions.eq(next_q_index).fadeIn('fast', function() {
          // Then fade in the next answer.
          answers.eq(next_a_index).fadeIn('fast', function() {
            // Add the active class to the next q/a:
            questions.eq(next_q_index).addClass('active');
            answers.eq(next_a_index).addClass('active');
          });
        });
      });
    });

    // Do some recursion.
    setTimeout(amimate_hp_ctas,5000);
  }

  /* 
  ################################################
  ## -- Begin approach questionnaire methods -- ##
  ################################################
  */

  // @TODO: Update code so references to window.answers are content-agnostic.
  //  For example, instead of referring to prop names, use for(var i in $set).

  // global answers object in window:
  // window.answers = { 'process' : '' , 'marketing' : ''};

  // Get info about selected answers. Return OBJ contains:
  function quiz_answered() {
    var the_answers = window.answers,
        has_answers = true,  // whether all the questions have answers
        any_unsure  = false; // whether any answers are "unknown"
    // Loop through answers, determining set vs unsure.
    for (var i in the_answers) {
      has_answers = (has_answers && the_answers[i] !== "");
      any_unsure = (any_unsure || the_answers[i] === "unknown");
    }
    // Return:
    return {
      'all'    : has_answers,
      'unsure' : any_unsure
    };
  }

  // Check the radio/select answers by a name/value pair and update global.
  function toggle_answer(name,value) {
    // Update the radio or select element.
    if (value !== "") {
      $(".questionnaire-normal input[type=radio][name=" + name + 
          "][value=" + value + "]").prop('checked',true);
      $(".questionnaire-mobile select[name=" + name + 
          "] option[value=" + value + "]").prop('selected',true);
    } else {
      $(".questionnaire-normal input[type=radio][name=" + name + 
          "]").prop('checked',false);
      $(".questionnaire-mobile select[name=" + name + 
          "] option[value=select]").prop('selected',true);
    }
    // Update the global answers.
    window.answers[name] = value;
  }

  // Get segment target from answers.
  function get_seg_from_answered() {
    var segment = "", answered = quiz_answered();
    if (answered.unsure) {
      return "not-sure";
    }
    if (answered.all) {
      var process = window.answers.process,
          mktg    = window.answers.marketing;
      switch (process + "|" + mktg) {
        case "creative|drives":
          return "entrepreneurs";
        case "strict|drives":
          return "marketing-machines";
        case "creative|supports":
          return "product-sales";
        case "strict|supports":
          return "business-scientists";
        case "unknown|unknown":
          return "not-sure";
      }
    }
    // still here?
    return false; // defaults to "all"
  }

  // Filter the segments based on the answers.
  function quiz_filter_segments(target) {
    
    // If target isn't set, determine it by looking at the answers.
    if (!target) {
      var seggy = get_seg_from_answered();
      if (seggy !== false) {
        target = seggy;
      } 
    }

    // If target still not set, make it "all".
    if (!target) {
      target = "all";
    } //console.log("filtering segment " + target + ":");

    // Set active class on appropriate banner item.
    $(".segmentation-banner a").removeClass("active");
    $(".segmentation-banner a[data-target=option_" + target + "]").addClass("active");

    // Greyout the appropriate segment(s).
    if (target === "all") {
      // Remove disabled class to show all segments.
      $(".segmentation-option").removeClass("disabled");
    } else {
      // Add disabled class to softhide all segments.
      $(".segmentation-option").addClass("disabled");
      // Remove disabled on specific segment to show it.
      $(".segmentation-option[data-segment=option_" + target + "]").removeClass("disabled");
    }

    // Filtering Done!
  }

  // Approach Quiz Section
  function quiz_reorder_segments() {
    
    var $segs, $cur_segment, $sorted,
        $before = $(".approach.segmentation-option").first().prev(),
        $after  = $(".approach.segmentation-option").last().next();
    var compare = function(aa, bb) {
      var a,b;
      // reinit
      a = ($(aa).attr('data-order-init'));
      b = ($(bb).attr('data-order-init'));
      if (typeof a === "string" && "".localeCompare) {
        return a.localeCompare(b);
      }
      return a === b ? 0 : a > b ? 1 : -1;
    };

    // Get the current segment. Since this is called after the filtering takes place,
    // we can get the current segment from the segmentation banner's active state.
    $cur_segment = $(".segmentation-banner a.active").attr('data-target');
    $cur_segment = $cur_segment.replace('option_','');

    if ($cur_segment === false || $cur_segment === "all") {
      // No items are selected; get the segments and detach from DOM.
      $segs = $(".approach.segmentation-option").detach();
      $sorted = $segs.clone();
      $sorted = $sorted.sort(compare);
      $after.before($sorted);

    } else {

      // Something is selected; get the selected segment and detach it from DOM.
      var $first  = $(".approach.segmentation-option[data-segment=option_" + $cur_segment + "]").detach();
      // Get the reamining segment and detach from DOM.
      $segs = $(".approach.segmentation-option").detach();
      $sorted = $segs.clone();
      $sorted = $sorted.sort(compare);
      $after.before($first);
      $after.before($sorted);
      
    }

  }

  // When a radio/select answer is clicked/changed, then filter&reorder if needed.
  function quiz_answer_select(event, element) {
    // dim vars
    var $el = $(element), newvalue = "";

    // First, update the corresponding radio or select element:
    if ($el.is("input[type=radio]")) { 
      // Changed radio, so update select.
      $(".questionnaire-mobile select[name=" + $el.attr('name') + 
          "] option[value=" + $el.val() + "]").prop('selected',true);
      newvalue = $el.val();
    } else if ($el.is("select")) { 
      // Changed select, so update radio.
      if ($el.val() === "select") {
        // Blank value set.
        $(".questionnaire-normal input[type=radio][name=" + $el.attr('name') + 
          "]").prop('checked',false);
        newvalue = "";
      } else {
        $(".questionnaire-normal input[type=radio][name=" + $el.attr('name') + 
          "][value=" + $el.val() + "]").prop('checked',true);
        newvalue = $el.val();
      }
    }

    // Next, update the Global answers array.
    window.answers[$el.attr('name')] = newvalue;

    // If filtering or reordering is needed, do it now.
    var answered = quiz_answered();
    if (answered.all || answered.unsure) {
      quiz_filter_segments();
      quiz_reorder_segments();
    }
  }

  // When the segmentation banner is clicked, update answers, then filter&reorder.
  function banner_select(event, element) {
    var $el = $(element), answers_before, answers_after;
    
    // Make a copy of the answers obj for testing if seg changed.
    answers_before = $.extend( {}, window.answers );

    // Determine the segment targeted by the banner-click.
    var target_segment = $el.data('target').replace('option_', '');
    //console.log("target_segment = " + target_segment);

    // Update the answers based on the segment.
    if (target_segment === "all") {
      // Select no answers:
      toggle_answer("process","");
      toggle_answer("marketing","");
    } else {
      // Select corresponding answers:
      switch(target_segment) {
        case "entrepreneurs":
          toggle_answer("process","creative");
          toggle_answer("marketing","drives");
          break;
        case "marketing-machines":
          toggle_answer("process","strict");
          toggle_answer("marketing","drives");
          break;
        case "product-sales":
          toggle_answer("process","creative");
          toggle_answer("marketing","supports");
          break;
        case "business-scientists":
          toggle_answer("process","strict");
          toggle_answer("marketing","supports");
          break;
        case "not-sure":
          toggle_answer("process","unknown");
          toggle_answer("marketing","unknown");
          break;
      }
    }

    // Make a copy of the answers obj, then test if seg changed.
    answers_after = $.extend( {}, window.answers );
    //@TODO: make this content-agnostic
    if (answers_before.process === answers_after.process && answers_before.marketing === answers_after.marketing) { 
      //console.log("answers didnt change. no filter/order needed.");
    } else {
      //console.log("answers have changed. we need to filter/order.");
      quiz_filter_segments(target_segment);
      quiz_reorder_segments();
    }

  }

  /* 
  ##############################################
  ## -- END approach questionnaire methods -- ##
  ##############################################
  */

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(".navbar-toggle").click(function(){
          if ($(this).hasClass("collapsed")) {
            console.log(this);
          }
        });

        // all links that open an external resource should open in a new window
        $("a[href^='http://'],a[href^='https://']").each(function() {
            var url = this.href;
            var curhost = new RegExp('/' + window.location.host + '/');

            // if url doesnt match curhost, it totes is external.
            if (!curhost.test(url)) {
                $(this).attr('target','_blank');
            }
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        //$('.clients-slider').cycle();
        $('.clients-slider').owlCarousel({
          loop:true,
          center: false,
          mouseDrag: true,
          touchDrag: true,
          pullDrag: false,
          freeDrag: false,
          stagePadding: 0,
          mergeFit: true,
          autoWidth: true,
          autoHeight: false,
          startPosition: 0,
          navRewind: true,
          navText: ["prev","next"],
          slideBy: 1,
          dotsEach: false,
          dotData: false,
          autoplayHoverPause: true,           
          responsiveClass: false,
          videoHeight: false,
          videoWidth: false,
          fallbackEasing: 'swing',
          smartSpeed: 0,
          autoplayTimeout: 0,
          autoplaySpeed: 3000,
          // Overrides autoplaySpeed on a per-item basis.
          // item.speed = item.width / autoplaySpeedConstant
          autoplaySpeedConstant: "60px/s",
          slideTransition: 'linear',

          autoplay: true,
          nav: false,
          dots: false,            
          video: true,
          lazyLoad: true,
          margin: 8,
          responsive:{
            0   : { items: 2 },
            479 : { items: 5 },
            768 : { items: 6 },
            980 : { items: 7 },
            1199: { items: 8 }
          }
            
        });

        var $doc = $(document), $masthead = $("#masthead"),
            $nav = $("header nav.navbar"), 
            $hint = $(".scroll-hint a");

        $doc.scroll(function(){
          if ($doc.scrollTop() <= $masthead.outerHeight()) {
            $nav.removeClass('navbar-fixed-top').addClass('navbar-static-top');
          } else {
            $nav.removeClass('navbar-static-top').addClass('navbar-fixed-top');
          }
        });

        $hint.click(function(e){
          e.stopPropagation();
          $.scrollTo($hint,{
            duration: 400,
            easing: 'swing'
          });
          return false;
        });

        setTimeout(amimate_hp_ctas,5000);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about': {
      init: function() {
        // JavaScript to be fired on the about us page
        $(".about-person figure").each(function(i,e){
          $(e).css({
            "background-color":
            "rgb(" + randomIntFromInterval(0,255) + "," + 
                     randomIntFromInterval(0,255) + "," + 
                     randomIntFromInterval(0,255) + ")"
          });
        });
        $(".about-person figcaption").on("tap", function(e){
          var $this = $(this);
          var $overlay = $this.siblings('figure').children('person-overlay');
          if ($overlay.is(':hidden')) {
            // hide others, then show the overlay
            $("figure .person-overlay").hide();
            $overlay.show();  
          } else {
            // this one is displayed. show none.
            $("figure .person-overlay").hide(); // try to hide the overlay
            $this.parents('about-person').blur(); // try to lose focus
            e.stopPropagation(); // prevent tap from propagating up the chain
          }
        });

      }
    },
    'services': {
      init: function() {
        var $scrollers = $(".services-banner div>a");


        $scrollers.click(function(e){
          e.stopPropagation();
          $.scrollTo($("a.service_anchor[name=" + $(this).data('target') + "]"),{
            duration: 400,
            easing: 'swing'
          });
          return false;
        });
      },
      finalize: function () {

      }
    },
    // About us page, note the change from about-us to about_us.
    'approach': {
      init: function() {

        // Create an answers object to help with this page's questionnaire.
        if (window && !window.answers) {
          // window obj exists and answers var doesn't yet
          window.answers = {
            "process": "",
            "marketing": ""
          };
        }

        // Approach Quiz answers clicked on.
        $(".feature-tms_approach_survey .questionnaire-normal input[type=radio]").click(function(e){
          quiz_answer_select(e,this);
        });
        $(".feature-tms_approach_survey .questionnaire-mobile select").change(function(e){
          quiz_answer_select(e,this);
        });

        // Approach Banner heading clicked
        $(".segmentation-banner a").click(function(e){
          e.stopPropagation(); // dont click through to #
          banner_select(e, this); // trigger callback
          return false; // really dont click through to #
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
